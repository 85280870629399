<template>
  <div id="pay">
    <div class="bg"></div>
    <div class="pay_wrap">
      <div class="tips flex-container">
        <img src="@image/pay/pay_success.png" />
        <p>支付成功</p>
      </div>
      <!-- <p class='price'>实付: 312元</p> -->

      <div class="btn flex-container">
        <div class="" @click="gotoPath('HomeIndex')">返回首页</div>
        <div class="" @click="gotoPath('Order')">查看订单</div>
      </div>
    </div>

    <div
      class="image_url"
      @click="tohref(image_url.relevance_url)"
      v-if="image_url && image_url.relevance_image"
    >
      <img :src="'https://shop.19jiang.cn' + image_url.relevance_image" />
    </div>

    <div class="pay_title flex-container" v-if="likeList.length != 0">
      <p>经典搭配，大家都在买</p>
      <p>好作品要有好工具，琳琅好物任你挑</p>
    </div>

    <div class="product_list flex-container" v-if="likeList.length != 0">
      <div
        class="li"
        v-for="(item, index) in likeList"
        @click="
          $router.push({
            name: 'HomeGoodsdetail',
            query: { goods_id: item.goods_id },
          })
        "
        :key="index"
      >
        <img :src="item.goods_image" />
        <div class="info">
          <p>{{ item.goods_name }}</p>
          <div class="flex-container">
            <b class="flex1"
              >￥{{ getPriceDot(item.goods_price, 0) }}.<i>{{
                getPriceDot(item.goods_price, 1)
              }}</i></b
            >
            <span
              >售出:
              {{ item.goods_salenum + item.goods_vr_salenum || 0 }}笔</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post } from "@get/http";
export default {
  name: "",
  data: () => ({
    likeList: [],
    image_url: {},
    store_id_pay: false,
    store_id: process.env.VUE_APP_STORE,
  }),
  created() {
    let backlen = history.length - 1;

    // history.go(-backlen);
    this.productAutoGoRouter();
    this.likeProduct();
    // this.autoSend();
  },
  methods: {
    getPriceDot(val, model = 0) {
      if (model == 0) {
        return val.toString().split(".")[model];
      } else {
        return val.toString().split(".")[model];
      }
    },
    async productAutoGoRouter() {
      //产品购买是否跳转链接
      if (this.$route.query.pay_sn) {
        let { url } = await post("/Memberorder/getBuyOverLocationLink", {
          data: {
            pay_sn: this.$route.query.pay_sn,
          },
        });
        if (url) {
          this.tohref(url);
        }
      }
    },
    tohref(url) {
      window.location = url;
    },
    gotoPath(path) {
      this.$router.push({
        name: path,
      });
    },
    async likeProduct() {
      //获取相似商品
      if (this.$route.query.pay_sn || this.$route.query.out_trade_no) {
        let { goods_data, image_url } = await post(
          "/v2.Memberorder/getBuyOverGoodsList",
          {
            data: {
              pay_sn:
                this.$route.query.pay_sn ||
                this.$route.query.out_trade_no.replace(/[^0-9]/gi, ""),
            },
          }
        );
        this.likeList = goods_data;
        this.image_url = image_url;
      }
    },

    checkOrderInfo(pay_sn) {
      //检查订单支付信息
      if (this.$route.query?.check) {
        return false;
      }
      post("/lw.memberbuy/pay", {
        data: {
          pay_sn,
        },
      });
    },
    autoSend() {
      //自动发货
      if (this.$route.query?.out_trade_no) {
        this.checkOrderInfo(
          this.$route.query.out_trade_no.replace(/[^0-9]/gi, "")
        );
      }
      if (this.$route.query?.pay_sn) {
        this.checkOrderInfo(this.$route.query.pay_sn);
      }

      post("/Memberorder/auto_send", {
        data: {
          pay_sn:
            this.$route.query.pay_sn ||
            this.$route.query.out_trade_no.replace(/[^0-9]/gi, ""),
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";

#pay {
  position: relative;
  background: #f5f5f5;
}
.bg {
  height: rem(440);
  background: #ef4f4f;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
}
.pay_title {
  position: relative;
  z-index: 1;
  width: rem(700);
  height: rem(160);
  background: #fff;
  margin: 0 auto;
  border-radius: rem(20);
  flex-direction: column;
  justify-content: center;
  padding: 0 rem(40);
  box-sizing: border-box;
  box-shadow: 0px rem(3) rem(5) rgba(0, 0, 0, 0.1);
  p {
    &:first-of-type {
      color: #ef4f4f;
      font-size: rem(40);
    }
    &:last-of-type {
      color: #333333;
      font-size: rem(30);
    }
  }
}
.pay_wrap {
  position: relative;
  z-index: 1;
  .tips {
    padding-top: rem(100);
    align-items: center;
    justify-content: center;
    // margin-bottom: rem(6);
    margin-bottom: rem(54);
    img {
      width: rem(45);
      height: rem(45);
    }
    p {
      color: #fff;
      font-size: rem(50);
      font-weight: bold;
      margin-left: rem(20);
    }
  }

  .price {
    color: #fff;
    font-size: rem(40);
    text-align: center;
    margin-bottom: rem(40);
  }
  .btn {
    align-items: center;
    justify-content: center;
    margin-bottom: rem(60);
    div {
      width: rem(210);
      height: rem(64);
      line-height: rem(64);
      text-align: center;
      font-size: rem(32);
      color: #fff;
      border: 1px solid #fff;
      border-radius: rem(32);
      &:first-of-type {
        margin-right: rem(30);
      }
      &:last-of-type {
        margin-left: rem(30);
      }
    }
  }
}

.product_list {
  margin: 0 rem(24) rem(24);
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: space-between;

  .li {
    width: rem(338);
    box-shadow: 0px rem(3) rem(6) rgba(0, 0, 0, 0.05);
    border-radius: rem(20);
    background: #fff;
    margin-top: rem(24);
  }

  img {
    width: rem(338);
    height: rem(338);
    display: block;
    border-radius: rem(20) rem(20) 0 0;
  }
  .info {
    padding: rem(20) rem(20) rem(26);
    box-sizing: border-box;
    p {
      color: #000000;
      font-size: rem(24);
      @include ell2;
      min-height: rem(68);
      // margin-bottom: rem(4);
    }
    div {
      align-items: center;

      b {
        color: #ef4f4f;
        font-size: rem(30);
        font-weight: bold;
        i {
          font-size: rem(20);
          font-weight: bold;
        }
      }
      span {
        color: #999999;
        font-size: rem(20);
      }
    }
  }
}

.image_url {
  position: relative;
  margin: 0 auto rem(24);
  width: rem(710);
  img {
    width: 100%;
  }
}
</style>
